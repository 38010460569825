import React, { useState, useEffect } from 'react';
import { service } from '../../network/service';
import ReactHlsPlayer from 'react-hls-player';
import 'react-multi-carousel/lib/styles.css';
import { convertTimeToLocal, deviceDetect, playerController, ssaiAdParam } from '../../Utils/utils';
import videothumbnail from '../../images/videothumbnail.png';
var liveThumbnailUrl = 'https://gizmeon.mdc.akamaized.net/thumbnails/images/';

const handleScroll = () => {
    let playerId = 'singleVideo';
    if (deviceDetect() === true) {
        playerController(600, playerId);
    } else {
        playerController(150, playerId);
    }
}

const LiveContainer = () => {
    const [channels, setChannels] = useState([]);
    const [logo, setLogo] = useState('');
    const [videoPlayer, setVideoPlayer] = useState();
    const [livePlayerId, setLivePlayerId] = useState();

    useEffect(() => {
        service.getLiveChannels().then(response => {
            setLogo(response.data[0].image);
            setChannels(response.data[0]);
            if (response.data[0].live_link) {
                let videoElem = "live_video" + new Date().valueOf();
                setLivePlayerId(videoElem);
                setVideoPlayer(
                    ssaiAdParam(response.data[0]).then(params => {
                        window.playLivePlayer(videoElem, response.data[0], params);
                    })
                )
            }
        })

        setInterval(() => {
            service.getLiveChannels().then(response => {
                setLogo(response.data[0].image);
                setChannels(response.data[0]);
            })
        }, 60 * 1000);
        // window.addEventListener('scroll', handleScroll);

    }, []);
    return (
        <div className="entireBanner">
            <div className="hpLiveBanner">
                <div className="row">
                    <div className="col-md-9">
                        <div className="liveVideoWrapper" style={{ marginTop: '80px', marginLeft: '25px' }}>
                            {videoPlayer && (
                                <video id={livePlayerId} className="video-js vjs-default-skin mainPlayer"
                                    controls preload="auto"
                                    autoPlay muted>
                                </video>
                            )}
                            <div className="hpWrapperVideo">
                                {channels.now_playing && (
                                    <section className="movieTextWrapper vpRelatedMargin">
                                        <div className="vpRelatedImage">
                                            <img alt={channels.now_playing.video_title} src={liveThumbnailUrl + logo} width="100%" />
                                            <div className="liveTvBlackOverlay"></div>
                                            <div className="liveTvPlay"></div>
                                        </div>
                                        <div className="movieTextFlex">
                                            <div className="movieCatYear">
                                                <div>
                                                    <div className="movieCategory mcMargin">
                                                        <div>{channels.now_playing.start_time && convertTimeToLocal(channels.now_playing.start_time)}{channels.now_playing.start_time && "-"} {channels.now_playing.end_time && convertTimeToLocal(channels.now_playing.end_time)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3><div className="linkButton movieTextHeading">{channels.now_playing.video_title}</div></h3>
                                        </div>
                                    </section>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <ul className="dashboard-genres-pro download_apps" style={{ borderBottom: '0px solid #26262d', marginTop: '70px' }}>
                            <li><a className="gb_Fe gb_oc gb_We web_view" href="https://play.google.com/store/apps/details?id=com.adventure.sportstv" title="Android mobile app" target="_blank"><img src={require("../../images/play.png")} alt="Sports" className="app-img-icn image_size" style={{ border: '1px solid rgb(255, 255, 255)', borderRadius: '5%', width: '100% !important' }}></img></a></li>
                            <li><a className="web_view" href="https://channelstore.roku.com/details/558497/adventure-sports-tv" target="_blank" title="Rokku Channel"><img src={require("../../images/roku.png")} alt="News" className="app-img-icn image_size" style={{ border: '1px solid rgb(255, 255, 255)', borderRadius: '5%', width: '100% !important' }}></img></a></li>
                            <li><a className="web_view" href="https://play.google.com/store/apps/details?id=com.astv.android" target="_blank" title="Android TV app"><img src={require("../../images/tv.png")} alt="Movie" className="app-img-icn image_size" style={{ border: '1px solid rgb(255, 255, 255)', borderRadius: '5%', width: '100% !important' }}></img></a></li>
                            <li><a className="web_view" href="https://apps.apple.com/us/app/adventure-sports-tv/id1475218550?ls=1" target="_blank" title="ios app"><img src={require("../../images/apple.png")} alt="Music" className="app-img-icn image_size" style={{ border: '1px solid rgb(255, 255, 255)', borderRadius: '5%', width: '100% !important' }}></img></a></li>
                            <li><a className="web_view" href="https://www.amazon.com/gp/product/B07XHGXH3C" target="_blank" title="Fire TV app"><img src={require("../../images/FireTV.png")} alt="Music" className="app-img-icn image_size" style={{ border: '1px solid rgb(255, 255, 255)', borderRadius: '5%', width: '100% !important' }}></img></a></li>
                            <li><span className="web_view com-soon" target="_blank"><img src={require("../../images/lgtv.png")} title="Coming Soon" alt="Music" className="app-img-icn" style={{ border: '1px solid rgb(255, 255, 255)', borderRadius: '5%', width: '100% !important' }}></img></span></li>
                            {/* <li><span className="web_view com-soon" target="_blank"><img src={require("../../images/samsung.png")} title="Coming Soon" alt="Music" className="app-img-icn image_size" style={{ border: '1px solid rgb(255, 255, 255)', borderRadius: '5%', width: '100% !important' }}></img></span></li> */}
                        </ul>
                    </div>
                </div>

                <div className="overlayTiles">
                </div>
            </div>
        </div>
    );
};
export default LiveContainer;
